<template>
  <div class="layout-header-section">
    <div class="header-label col-lg-6 col-12">Payment Receipts</div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="paymentReceiptList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
      :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :lazy="true"
      dataKey="ak1" :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="date" header="Receipt Date" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div class="label-subheading">{{ format_timestamp(data.receipt_datetime) }}</div>
        </template>
      </Column>
      <Column field="amount" header="Amount" headerStyle="width: 15%" bodyStyle="width: 15%" class="justify-content-end">
        <template #body="{ data }">
          <div class="label-subheading">{{ vueNumberFormat(data.br20, {
            prefix: '₹ ', decimal: '.', thousand: ',',
            precision: 2, acceptNegative: true
          }) }}</div>
        </template>
      </Column>
      <Column field="paymentmode" header="Payment Mode" headerStyle="width: 35%" bodyStyle="width: 35%">
        <template #body="{ data }">
          <div class="label-subheading">
            <span v-if="data.br7 == 1">Payment Gateway</span>
            <span v-if="data.br7 == 2">Cheque</span>
            <span v-if="data.br7 == 3">NEFT / RTGS</span>
            <span v-if="data.br7 == 4">IMPS</span>
            <span v-if="data.br7 == 5">Advance Credit</span>
            <span v-if="data.br7 == '' || data.br7 == null">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 15%" bodyStyle="width: 15%"
        class="justify-content-center">
        <template #body="{ data }">
          <div class="label-subheading">
            <span v-if="data.br19 == 1" class="appointment-badge status-scheduled">Success</span>
            <span v-if="data.br19 == 2" class="appointment-badge status-cancelled">Failed</span>
            <span v-if="data.br19 == '' || data.br19 == null">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="download" header="Download" headerStyle="width: 15%" bodyStyle="width: 15%"
        class="justify-content-center">
        <template #body="{ data }">
          <a v-if="data.br15 != '' && data.br15 != null" class="btn custom-outline-download-btn" target="_blank"
            :href="'https://storage.googleapis.com/' + this.receiptstoragefilepath + '/' + this.client_id + '/payment-receipt/' + data.br15" download="Download Receipt"><i class="pi pi-download download-btn-color"></i></a>
          <span v-if="data.br15 == '' || data.br15 == null">-</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../../../service/ApiService";
import commonMixin from "../../../mixins/commonMixin.js";
import moment from 'moment';
export default {
  mixins: [commonMixin],
  data() {
    return {
      paymentReceiptList: [],
      totalRecords: 0,
      page_no: 0,
      loading: false,
      client_info: '',
      client_id: '',
      receiptstoragefilepath: '',
      baseUrl :window.location.origin,
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.client_id = this.client_info.maa1;
    if (this.client_id != '') {
      this.getHostPaymentReceipt({ client_id: this.client_id, page_no: this.page_no, });
    }
    if(this.baseUrl == 'http://localhost:8080') {
      this.receiptstoragefilepath = this.client_info.maa24;
    } else {
      this.receiptstoragefilepath = localStorage.masterbucketname;
    }
  },
  methods: {
    getHostPaymentReceipt(e) {
      this.loading = true;
      this.ApiService.getHostPaymentReceipt(e).then((data) => {
        if (data.status == 200) {
          this.paymentReceiptList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.paymentReceiptList = null;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getHostPaymentReceipt({ page_no: this.page_no });
    },
    format_datetime(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
  },
};
</script>
<style scoped>
.custom-outline-download-btn {
  background: #e7f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-download-btn .download-btn-color {
  color: #5266e5;
  font-size: 13px;
}
</style>